.display1{
  font-weight: 700;
  font-size: 4em;
  line-height: 131%;
  letter-spacing: -0.02em;
}

.display2{
  font-weight: 700;
  font-size: 3em;
  line-height: 145%;
  letter-spacing: -0.02em;
}

.display3{
  font-weight: 700;
  font-size: 2em;
  line-height: 160%;
  letter-spacing: -0.02em;
}

.display4,
.display5,
.display6{
  font-weight: 700;
  line-height: 150%;
}

.display4{
  font-size: 1.375em;
}

.display5{
  font-size: 1.125em;
}

.display6{
  font-size: 1em;
}


.text20,
.text16,
.text14{
  p{
    line-height: inherit;
    &:not(:last-child){
      margin-bottom: .5em;
    }
  }
}

.text44{
  font-size: 2.75em;
}

.text30{
  font-size: 1.875em;
  line-height: 127%;
}

.text28{
  font-size: 1.75em;
  line-height: 107%;
}

.text24{
  font-size: 1.5em;
  line-height: 120%;
}

.text20{
  font-size: 1.25em;
  line-height: 150%;
}

.text18{
  font-size: 1.125em;
  line-height: 156%;
}

.text16{
  font-size: 1em;
  line-height: 150%;
}

.text14{
  font-size: .875em;
  line-height: 150%;
}

.text12{
  font-size: .75em;
  line-height: 150%;
}

.gray-text{
  color: $grayText;
}

.red-text{
  color: $red;
}

.blue-text{
  color: $blue;
}

.default-blue-text{
  color: $defaultBlue;
}

.light-blue-text{
  color: $lightBlue;
}

.bold-weight{
  font-weight: 700;
}

.semi-bold{
  font-weight: 600;
}

.medium-weight{
  font-weight: 500;
}

.light-weight{
  font-weight: 300;
}

.mlist{
  li{
    line-height: inherit;
    display: flex;
    &::before{
      content: '';
      display: block;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #000;
      transform: translateY(8px);
      margin-right: 5px;
      flex-shrink: 0;
    }
    &:not(:last-child){
      margin-bottom: 16px;
    }
  }
}


@media screen and (max-width: 992px){
  .text18-mob{
    font-size: 1.125em;
    line-height: 156%;
  }
}
