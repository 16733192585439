@import "../mixins";

.modal{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    z-index: 1000;
    opacity: 0;
    pointer-events: none;
    @media screen and (max-width: 992px){
        @media screen and (min-width: 577px){
            font-size: 30%;
        }
    }
    &:not(.modal2){
        @media screen and (min-width: 577px) {
            text-align: center;
            &:before {
                display: inline-block;
                vertical-align: middle;
                content: " ";
                height: 100%;
            }
            .modal__dialog {
                display: inline-block;
                text-align: left;
                vertical-align: middle;
            }
        }
    }

    &--active {
        opacity: 1;
        pointer-events: unset;
    }
    &__bg{
        position: fixed;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        background: rgba(102, 112, 133, 0.6);
    }
    &__dialog {
        margin: auto;
        background-color: #fff;
        border-radius: 1.25em;
        width: 26em;
        max-width: calc(100% - 2em);
        padding: 4.5em 2.9em 3.75em;
        position: relative;
    }
    &__close{
        font-size: 1em;
        position: absolute;
        top: 1.5em;
        right: 1.5em;
        width: 1.5em;
        height: 1.5em;
        img{
            width: 100%;
            transition: all .3s;
        }
        &:hover{
            img{
                opacity: .6;
            }
        }
    }
    &__info{
        @include grid100gap(.5em);
    }
    &__title{
        font-size: 1.75em;
        line-height: 107%;
        font-weight: 500;
    }
    &__form{
        margin-top: 1.5em;
        .fg{
            margin-bottom: .5em;
        }
    }
    &__socials{
        margin-top: 2em;
        @include grid100gap(1.5em);
        &-items{
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    &__social{
        padding: 0;
        width: 3em;
        border-radius: .1875em;
        &:not(:last-child){
            margin-right: 1em;
        }
        img{
            width: 1.5em;
        }
    }
    &__btn{
        width: 100%;
        margin-top: 2.25em;
    }
    &__hint{
        margin-top: .75em;
    }
}

.reg-modal{
    &__buttons{
        @include grid100gap(1.5em);
        margin-top: 1.5em;
    }
    &__hint{
        margin-top: .5em;
    }
    &__hint2{
        margin-top: 2.25em;
    }
}

.client-modal2{
    &__hint{
        margin-top: 1.5em;
    }
    .fg{
        margin-bottom: 0 !important;
        &:not(:first-child){
            margin-top: 1.5em;
        }
    }
}

.client-modal3{
    .modal{
        &__dialog{
            width: 48em;
        }
    }
    &__title{
        @media screen and (min-width: 577px){
            br{
                display: none;
            }
        }
    }
    &__grid{
        display: grid;
        grid-template-columns: repeat(4,1fr);
        gap: 1.5em 2em;
        margin-top: 1.5em;
    }
    &__group{
        @media screen and (min-width: 577px){
            &--25{
                grid-column: 2/5;
            }
            &--13{
                grid-column: 1/3;
            }
            &--35{
                grid-column: 3/5;
            }
            &--check{
                display: flex;
                align-items: flex-end;
                .m-check{
                    display: flex;
                    align-items: center;
                    height: 2.75em;
                }
            }
        }
    }
    &__nav{
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: .75em;
        grid-column: 1/-1;
        .m-btn{
            width: 20em;
            max-width: 100%;
        }
    }
}

.confirm-modal{
    &__desc{
        span{
            display: block;
            font-weight: 700;
        }
    }
}

.modal2{
    .modal{
        &__dialog{
            width: 100%;
            max-width: 100%;
            border-radius: 1em 1em 0 0;
            position: absolute;
            bottom: 0;
            left: 0;
            overflow-y: auto;
            max-height: calc(100vh - 2em);
            min-height: 47.5em;
            padding-left: 0;
            padding-right: 0;
        }
    }
    .container--full{
        max-width: 100%;
        padding: 0 3em;
    }
}

.product-modal{
    &__title{
        @media screen and (min-width: 577px){
            br{
                display: none;
            }
        }
        &.normal-weight{
            font-weight: 400;
        }
    }
    &__content{
        margin-top: 1.5em;
        padding: 0 2em;
    }
    &__wrap{
        padding-bottom: 7.5em;
    }
    &__bottom{
        z-index: 3;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0px -4px 16px rgba(0, 51, 153, 0.04), 0px -2px 2px rgba(0, 51, 153, 0.08);
    }
    &__action{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 4.5em;
    }
    &__cart{
        display: flex;
        align-items: center;
        .cart-btn{
            width: 9.75em;
            &._added{
                &~.cartcalc{
                    display: grid;
                }
            }
        }
        .cartcalc{
            margin-left: .75em;
            display: none;
        }
    }
    &__subtitle{
        margin-bottom: 1.5em;
    }
}

.product-desc{
    display: flex;
    &__info{
        width: 23.5em;
        margin-right: 2em;
        flex-shrink: 0;
    }
    &__groups{
        @include grid100gap(1em);
    }
    &__group{
        @include grid100gap(.25em);
    }
    &__img{
        width: 25em;
        img{
            width: 100%;
        }
    }
}

.product-gallery{
    flex-shrink: 0;
    display: grid;
    grid-template-columns: 25em 5em;
    gap: .625em;
    &__big,
    &__small{
        width: 100%;
        height: 25em;
    }
    &__big{
        .swiper{
            &-slide{
                @include center;
            }
        }
    }
    &__small{
        .swiper{
            &-slide{
                width: 100%;
                height: 6em;
                padding-bottom: 1em;
                &-thumb-active{
                    pointer-events: none;
                    .product-gallery__img{
                        border-color: transparent;
                        opacity: .25;
                    }
                }
                &:hover{
                    cursor: pointer;
                    .product-gallery__img{
                        border-color: $defaultBlue;
                    }
                }
            }
        }
        .product-gallery__img{
            border: 1px solid rgba(102, 112, 133, 0.25);
            transition: all .3s;
        }
    }
    &__img{
        position: relative;
        padding-bottom: 100%;
        width: 100%;
        img{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            max-width: 100%;
            max-height: 100%;
        }
    }
}

.product-text{
    flex-grow: 1;
    margin-left: 2em;
    &__desc{
        margin-right: .25em;
        p{
            line-height: inherit;
            &:not(:last-child){
                margin-bottom: 1.5em;
            }
        }
        b{
            font-weight: 700;
        }
    }
}

.faq-modal{
    &__btn{
        margin-top: 1.875em;
    }
    .thank-contacts{
        margin-top: 6em;
    }
}

.quiz-modal{
    &__grid{
        display: grid;
        grid-template-columns: calc(100% - 26em) 23em;
        gap: 3em;
        margin-top: .75em;
    }
    &__left{
        padding: 3em 3em 3em 0;
        display: flex;
        justify-content: center;
    }
    &__form{
        width: 32em;
        max-width: 100%;
        @include grid100gap(1.5em);
    }
    &__btn{
        margin-top: .5em;
    }
    &__back{
        display: inline-flex;
    }
    &__interest{
        &-links{
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1em 1.5em;
            margin-top: 1em;
            //max-width: 31.125em;
        }
        ._tab{
            padding: 0;
            font-weight: 500;
            font-size: 1em;
            line-height: 150%;
            color: $defaultBlue;
            span{
                border-bottom: 1px dashed $defaultBlue;
            }
            &:before{
                display: none;
            }
            &._active{
                color: $lightBlue;
                span{
                    border-color: transparent;
                }
            }
        }
        ._tab-content{
            margin-top: 1.5em;
        }
    }
    &__check{
        label{
            color: $grayText;
        }
    }
}

.quiz-contacts{
    padding: 2em;
    background: #F9FAFB;
    border-radius: 1.5em;
    &__top{
        @include grid100gap(1em);
    }
    &__items{
        @include grid100gap(2em);
        margin-top: 2em;
    }
    .contacts{
        &__title{
            min-height: unset;
            height: unset;
            margin-bottom: .5em;
        }
        &__icon{
            width: 1.5em;
            display: block;
            height: auto;
            background-color: transparent;
            margin-right: 1em;
            border: none;
        }
    }
}

.quiz-answer{
    padding: 1em;
    background: #F9FAFB;
    border-radius: 1.5em;
    &__top{
        display: flex;
        justify-content: space-between;
    }
    &__close{
        flex-shrink: 0;
        margin-left: 1em;
        font-size: 1em;
        img{
            width: 1.5em;
        }
    }
    &__text{
        margin-top: .5em;
    }
}


@media screen and (max-width: 576px){
    .modal{
        &__dialog{
            border-radius: 1em 1em 0 0;
            width: 100% !important;
            max-width: 100%;
            padding-right: 1em;
            padding-left: 1em;
            position: absolute;
            bottom: 0;
            left: 0;
            overflow-y: auto;
            max-height: calc(100vh - 2em);
        }
    }

    .client-modal3{
        &__grid{
            grid-template-columns: 100%;
        }
    }

    .modal2{
        .modal{
            &__dialog{
                min-height: auto;
            }
        }
        .container{
            padding: 0 1em;
        }
    }

    .product-modal{
        &__title{
            .gray-text{
                display: block;
            }
        }
        &__content{
            padding: 0;
        }
        &__action{
            display: grid;
            grid-template-columns: repeat(2,calc(50% - .375em));
            gap: .75em;
        }
        &__cart{
            .cart-btn{
                &._added{
                    display: none;
                }
            }
            .cartcalc{
                margin-left: 0;
                width: 100%;
            }
        }
    }

    .product-desc{
        display: block;
        &__info{
            width: 100%;
            margin-bottom: 2em;
        }
        &__img{
            width: 100%;
        }
    }

    .product-gallery{
        display: block;
        &__big{
            width: 100%;
        }
        &__small{
            width: calc(100% + 2em);
            padding: 0 1em;
            height: 5em;
            margin-top: 1em;
            margin-left: -1em;
            .swiper{
                &-slide{
                    width: 6em;
                    height: unset;
                    padding: 0 1em 0 0;
                }
            }
            .product-gallery__img{
                padding: 0;
                width: 100%;
                height: 5em;
            }
        }
    }

    .product-text{
        margin: 2em 0 0;
    }

    .quiz-modal{
        &__left{
            padding: 0;
        }
        &__grid{
            grid-template-columns: 100%;
        }
        &__interest{
            &-links{
                grid-template-columns: 100%;
            }
        }
    }

    .quiz-contacts{
        padding: 2em 1em;
    }
}
