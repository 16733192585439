@import "../mixins";
.fg {
  position: relative;
  margin: 0;
  label{
    font-weight: 500;
    font-size: .875em;
    line-height: 143%;
    color: #344054;
    display: block;
    &~textarea,
    &+input,
    &~.nice-select{
      margin-top: .375em;
    }
  }
  .nice-select{
    float: unset;
    width: 100%;
    height: unset;
    background-color: transparent !important;
    border: none !important;
    padding: 0;
    font-size: 1em;
    .current{
      display: flex;
      align-items: center;
      background-image: url(../img/chevron-down.svg);
      background-repeat: no-repeat;
      background-position: right .875em center;
      background-size: 1.25em auto;
    }
    &.open{
      .current{
        background-image: url(../img/chevron-up.svg);
        border-color: $lightBlue;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF;
      }
    }
  }
  .list{
    min-width: 100%;
    width: auto;
    margin-top: .5em;
    background: #FFFFFF;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05);
    border-radius: .5em;
    border: none;
    padding: .25em 0;
  }
  .option{
    height: 2.75em;
    display: flex;
    align-items: center;
    font-size: 1em;
    padding: 0 4em 0 .875em;
    font-weight: 400;
    border: none !important;
    background-repeat: no-repeat;
    background-position: right .875em center;
    background-size: 1.25em auto;
    &.focus,
    &.selected,
    &:hover{
      background-color: #FCFAFF !important;
      font-weight: 400 !important;
    }
    &.selected{
      background-image: url(../img/check.svg);
    }
    &.disabled{
      display: none;
    }
  }
  .current,
  input,
  textarea{
    width: 100%;
    height: 2.75em;
    font-size: 1em;
    line-height: 150%;
    padding: 0 .875em;
    border: $border;
    border-radius: .5em;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    color: $black;
    &::placeholder {
      color: $grayText;
    }
    &:focus,
    &:active{
      border-color: $lightBlue;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF;
    }
    &:disabled{
      background: #F9FAFB;
      pointer-events: none;
    }
  }
  [type="password"]{
    color: $blue;
  }
  textarea{
    height: 10.875em;
    resize: none;
    padding-top: .625em;
    padding-bottom: .625em;
  }
  &__icon{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: .875em;
    &~input{
      padding-left: 2.625em;
    }
  }
  &__hint,
  &__error-text{
    margin-top: .375em;
  }
  &__error-text{
    display: none;
  }
  .pass-toggle{
    position: absolute;
    right: .875em;
    bottom: .75em;
    width: 1.25em;
    height: 1.25em;
    background-image: url(../img/eye-off.svg);
    @include bgImgSize(100%);
    font-size: 1em;
    &._active{
      background-image: url(../img/eye.svg);
    }
    &~input{
      padding-right: 2.625em;
    }
  }
  &._error{
    label{
      color: $red;
    }
    input{
      border-color: #FDA29B;
      box-shadow: 0px 1px 2px rgba(240, 68, 56, 0.05), 0px 0px 0px 4px rgba(240, 68, 56, 0.1);
      background-image: url(../img/alert.svg);
      background-size: 1em auto;
      background-position: right .875em center;
      background-repeat: no-repeat;
    }
    .fg__error-text{
      display: block;
    }
  }
}

.m-check,
.m-radio{
  position: relative;
  input {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 2;
    cursor: pointer;
    &:disabled+label {
      &:before{
        border-color: #E4E7EC;
        background-color: #F2F4F7;
      }
    }
    &:hover+label {
      &:before{
        border-color: $blue;
        background-color: #F9F5FF;
      }
    }
    &:focus+label,
    &:active+label{
      &:before{
        box-shadow: 0px 0px 0px 4px rgba($blue,.1);
        border-color: #D6BBFB;
        background-color: #FFF;
      }
    }
  }
  label {
    display: flex;
    align-items: center;
    min-height: 20px;
    font-size: 1em;
    cursor: pointer;
    &:before {
      content: '';
      z-index: 1;
      border: $border;
      width: 1em;
      height: 1em;
      transition: background-color .100s, border-color .100s;
      flex-shrink: 0;
      margin-right: .5em;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  &._not-label{
    label{
      &:before{
        margin-right: 0;
      }
    }
  }
}

.m-check{
  input{
    &:checked+label {
      &::before {
        border-color: $blue;
        background-image: url(../img/check2.svg);
      }
    }
    &:checked:disabled+label {
      &::before {
        background-image: url(../img/check3.svg);
      }
    }
    &._semi-check+label{
      &:before{
        border-color: $blue;
        background-image: url(../img/minus2.svg) !important;
      }
    }
  }
  label{
    &:before{
      border-radius: .25em;
      background-size: .75em auto;
    }
  }
}

.m-radio{
  input{
    &:checked+label {
      &::before {
        border-color: $blue;
        background-image: url(../img/radio.svg);
      }
    }
    &:checked:disabled+label {
      &::before {
        background-image: url(../img/radio2.svg);
      }
    }
  }
  label{
    &:before{
      border-radius: 50%;
      background-size: .375em auto;
    }
  }
}

.file-input{
  position: relative;
  input{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }
}
