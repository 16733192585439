@import "../mixins";
.faq-btn{
  position: fixed;
  right: 2.5em;
  bottom: 2.5em;
  z-index: 10;
  width: 4em;
  height: 4em;
  border-radius: 50%;
  background: $defaultBlue;
  font-size: 1em;
  transition: all .3s;
  &:hover{
    transform: scale(1.1);
  }
  &:after{
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% + .625em);
    height: calc(100% + .625em);
    border: .625em solid rgba($defaultBlue, 0.12);
    transform: translate(-50%,-50%);
    border-radius: 50%;
  }
  img{
    width: 2em;
  }
}

.footer{
  padding: 3em 0 4em;
  border-top: $border;
  &__top{
    display: grid;
    grid-template-columns: repeat(5,1fr);
    gap: 2em;
  }
  &__title{
    font-size: .875em;
    line-height: 143%;
    color: #98A2B3;
  }
  &__items{
    margin-top: 1em;
    @include grid100gap(.75em);
  }
  &__link{
    color: $grayText;
    font-size: 1em;
    line-height: 150%;
    &[data-badge]{
      &:after{
        content: attr(data-badge);
        display: inline-flex;
        align-items: center;
        height: 1.83em;
        padding: 0 .67em;
        font-size: .75em;
        background: rgba(170, 192, 249, 0.25);
        border-radius: 1.33em;
        color: $blue;
        mix-blend-mode: multiply;
      }
    }
  }
  &__mail{
    color: $defaultBlue;
  }
  &__bottom{
    margin-top: 4em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 2em;
    border-top: $border;
  }
  &__left{
    display: flex;
    align-items: center;
  }
  &__logo{
    margin-right: 2.5em;
  }
  &__pay{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: -1.5em;
    &-item{
      width: 3.0625em;
      display: block;
      margin-top: 1.5em;
      &:not(:last-child){
        margin-right: 1.5em;
      }
      img{
        width: 100%;
      }
    }
  }
  &__c{
    color: #98A2B3;
  }
}


@media screen and (max-width: 992px){
  .faq-btn{
    right: 1em;
    bottom: 1em;
  }

  .footer{
    padding-bottom: 2em;
    &__top{
      grid-template-columns: 100%;
    }
    &__bottom{
      margin-top: 2em;
      display: block;
    }
    &__left{
      display: block;
    }
    &__logo{
      margin: 0 0 1.5em;
    }
    &__c{
      margin-top: 2em;
    }
  }
}
