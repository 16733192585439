@import "../mixins";
.pay{
  margin-top: 1.5em;
  background: #FFFFFF;
  border: $border2;
  box-shadow: $shadow;
  border-radius: 1em;
  &__items{
    padding: 2em 2em 2.5em;
    display: grid;
    grid-template-columns: repeat(3,calc(100% / 3 - 1em));
    gap: 1.5em;
  }
  .order-detail__top{
    border-top: $border2;
  }
  &__detail{
    border-top: $border2;
    padding: 2em 2em 2.5em;
    display: grid;
    grid-template-columns: repeat(2,calc(50% - .75em));
    gap: 1.5em;
    &-col{
      max-width: 18em;
    }
    &-title{
      margin-bottom: .25em;
    }
  }
}

.pay-card{
  padding: 1.5em;
  background: rgba(170, 192, 249, 0.12);
  border-radius: 1em;
  display: flex;
  flex-direction: column;
  &__icon{
    border-radius: 50%;
    width: 3em;
    height: 3em;
    background-color: #fff;
    @include center;
    margin-bottom: 2em;
    img{
      width: 1.5em;
    }
  }
  &__desc{
    margin: .5em 0 1.25em;
    max-width: 14.875em;
  }
  &__nav{
    display: flex;
    margin-top: auto;
  }
}


@media screen and (max-width: 992px){
  .pay{
    margin-top: 2em;
    background-color: transparent;
    box-shadow: none;
    border: none;
    border-radius: 0;
    &__bottom,
    &__items{
      border-radius: 1em;
      box-shadow: $shadow;
      border: $border2;
      background-color: #fff;
    }
    &__items{
      grid-template-columns: 100%;
      padding: 1em;
    }
    &__bottom{
      margin-top: 1.5em;
      position: relative;
      z-index: 2;
    }
    .order-detail__top {
      border-top: none;
    }
    .order-detail__buttons{
      margin-top: 1em;
    }
    &__detail{
      padding: 1em 1em 1.5em;
      grid-template-columns: 100%;
    }
  }
}
