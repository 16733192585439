@import "../mixins";
.lk{
  padding-bottom: 6em;
  &__content{
    margin-top: 1.5em;
  }
  &__subtitle{
    margin-bottom: 1.5em;
  }
}

.personal-info{
  @include grid3;
  max-width: 63em;
  &__full{
    grid-column: 1/-1;
    max-width: calc((100% / 3) * 2 - .5em);
  }
}

.info-sale{
  margin-top: 1.5em;
  &__groups{
    @include grid100gap(1em);
    margin-top: 1.5em;
  }
  &__group{
    @include grid100gap(.25em);
    .m-btn{
      display: inline-flex;
      margin-left: .5em;
    }
  }
}

.lk-orders,
.lk-orders2{
  width: calc(100% + 2em);
  margin-left: -2em;
  background-color: #fff;
  border-radius: 1em;
  box-shadow: $shadow;
  border: $border2;
}

.lk-orders{
  padding: 1em 2em 2em;
}

.lk-table{
  @media screen and (min-width: 993px){
    table{
      box-shadow: none;
      background-color: transparent;
    }
    th,
    td{
      border-left: none !important;
      border-right: none !important;
      border-radius: 0 !important;
      padding: 0 0 0 1.5em;
      &:nth-child(1){
        width: 12.5em;
      }
      &:nth-child(3){
        width: 6em;
      }
      &:nth-child(5){
        text-align: right;
        width: 7.5em;
      }
      &:nth-child(6),
      &:nth-child(7){
        width: 7.5em;
      }
      &:nth-child(8){
        width: 18em;
      }
      &:nth-child(9){
        width: 7.5em;
      }
    }
    th{
      border-top: none;
    }
  }
  &__address{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp:2;
    -webkit-box-orient: vertical;
  }
}

.lk-orders2{
  padding: 1em 2em;
  margin-top: 1.5em;
  .lk-table{
    padding-top: 1.5em;
  }
}

.messages{
  @include grid100gap(1.5em);
}

.message{
  &__top{
    display: flex;
  }
  &__img{
    flex-shrink: 0;
    width: 2.5em;
    height: 2.5em;
    margin-right: .75em;
    position: relative;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
      display: block;
    }
    &._shadow{
      img{
        filter: drop-shadow(0px 1px 3px rgba(16, 24, 40, 0.1)) drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.06));
      }
    }
  }
  &__online{
    position: absolute;
    width: .75em;
    height: .75em;
    background-color: #12B76A;
    border: 1px solid #fff;
    border-radius: 50%;
    bottom: -1px;
    right: -1px;
  }
  &__content{
    flex-grow: 1;
  }
  &__info{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__name{
    color: #344054;
  }
  &__date{
    white-space: nowrap;
    margin-left: 1em;
  }
  &__text{
    margin-top: .375em;
    padding: .625em .875em;
    background: #F2F4F7;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 0 .5em .5em .5em;
    a{
      color: $defaultBlue;
      white-space: nowrap;
    }
    .text16{
      max-width: 63.5em;
    }
  }
  &__nav{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: .375em;
    position: relative;
    &>.m-btn{
      margin-left: .25em;
    }
  }
  &._show-answer{
    @media screen and (min-width: 993px){
      .message__answer,
      .message__archive{
        opacity: 0;
        pointer-events: none;
      }
      .message-request{
        opacity: 1;
        pointer-events: unset;
      }
    }
  }
}

.message-request{
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-left: 3.25em;
  @media screen and (min-width: 993px){
    opacity: 0;
    pointer-events: none;
  }
  &__field{
    flex-grow: 1;
    position: relative;
    margin-right: .75em;
  }
  &__btn{
    z-index: 2;
    width: 10em;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0 .5em .5em 0;
  }
  &__cancel{
    flex-shrink: 0;
  }
}

.lk-pass{
  .fg{
    &__hint{
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
    }
  }
  &__nav{
    margin-top: 4.625em;
    display: flex;
  }
}


@media screen and (max-width: 992px){
  .lk{
    padding-bottom: 3em;
    &__content{
      margin-top: 2em;
    }
  }

  .personal-info{
      &__full{
        max-width: 100%;
      }
  }

  .info-sale{
    &__group{
      .m-btn{
        display: flex;
        margin: 1em 0 0 0;
      }
    }
  }

  .lk-orders{
    padding: 1.5em 1em;
    width: 100%;
    margin-left: 0;
  }

  .lk-table{
    td{
      grid-template-columns: 50% 50%;
    }
    .m-table__title,
    .m-table__desc{
      padding: .75em .625em .75em 1em;
    }
    &__address{
      -webkit-line-clamp:4;
    }
  }

  .lk-orders2{
    width: 100%;
    margin-left: 0;
    padding: 1em;
  }

  .lk-pass{
    gap: 3.125em;
    &__nav{
      margin-top: 3.125em;
    }
    &__btn{
      width: 100%;
    }
  }

  .message{
    &__nav{
      flex-wrap: wrap;
    }
    &._show-answer{
      .message__answer,
      .message__archive{
        opacity: 0;
        height: 0;
        overflow: hidden;
      }
    }
  }

  .message-request{
    position: static;
    display: none;
    width: 100%;
    margin-top: 1em;
    padding: 0;
    &__field{
      margin: 0;
      @include grid100gap(.5em);
    }
    &__btn{
      position: static;
      width: 100%;
      border-radius: .5em;
    }
    &__cancel{
      width: 100%;
      margin-top: .5em;
    }
  }
}
