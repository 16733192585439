@import "_header";
@import "_footer";
._show-product._disabled{
  pointer-events: none;
}

.cart-modal{
  position: absolute;
  padding: 1.5em;
  background: #FFFFFF;
  box-shadow: 0 .75em 2em rgba(0, 51, 153, 0.12), 0 .5em 1.25em rgba(0, 51, 153, 0.08);
  border-radius: .5em;
  top: 100%;
  right: 0;
  margin-top: .5em;
  z-index: 10;
  width: 16.875em;
  max-width: 100%;
  @include grid100gap(.5em);
  opacity: 0;
  pointer-events: none;
  transition: all .3s;
  &._active{
    opacity: 1;
    pointer-events: unset;
  }
  &__info{
    @include grid100gap(.125em);
    width: calc(100% + 1.25em);
    .text14{
      line-height: 200%;
    }
    .display6{
      line-height: 188%;
    }
  }
  &__badges{
    width: calc(100% + 1.25em);
    display: flex;
    flex-wrap: wrap;
    margin-top: -.25em;
    .m-badge{
      margin: .25em .25em 0 0;
    }
  }
  &__price{
    margin-top: .25em;
    .text16{
      line-height: 188%;
    }
  }
  &__btn{
    margin-top: .5em;
    width: 100%;
  }
}
