@import "../mixins";
.main-search{
  padding: 3em 0;
  &__form{
    display: flex;
  }
  &__btn{
    flex-shrink: 0;
    margin-left: 2em;
  }
  &__left{
    flex-grow: 1;
    @include grid100gap(.5em);
  }
  &__field{
    input{
      width: 100%;
      font-weight: 600;
      font-size: 2.75em;
      height: 1.364em;
      padding: 0 0 .25em;
      border: none;
      border-bottom: 1px solid $black;
      color: $defaultBlue;
      &::placeholder{
        color: $black;
      }
    }
  }
}

.main-collection{
  overflow: hidden;
  padding: 1em 0;
  .swiper{
    &-container{
      width: calc(100% + 2em);
    }
    &-slide{
      width: calc(100% / 3);
      padding-right: 2em;
    }
    &-button{
      position: static;
      margin: 0 2em 0 0;
    }
  }
  &__nav{
    display: flex;
    align-items: center;
    margin-top: 2em;
  }
}

.collection-card{
  height: 32em;
  position: relative;
  border-radius: 1em;
  &:hover{
    .collection-card__img img{
      transform: scale(1.05);
    }
  }
  &__img{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: .75em;
    img{
      max-width: 100%;
      max-height: 100%;
      transition: all .3s;
    }
  }
  &__icon{
    position: absolute;
    z-index: 2;
    top: 2em;
    left: 2em;
    max-width: 2.75em;
    max-height: 3em;
  }
  &__content{
    position: absolute;
    width: calc(100% - 3em);
    left: 50%;
    transform: translateX(-50%);
    bottom: 1.5em;
    padding: 2em 1.5em;
    height: 12.25em;
    display: flex;
    flex-direction: column;
    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 1em;
      background: rgba(255, 255, 255, 0.2);
      border: 1px solid rgba(255, 255, 255, 0.25);
      backdrop-filter: blur(12px);
    }
  }
  &__title,
  &__nav{
    position: relative;
    z-index: 2;
  }
  &__title{
    color: #fff;
  }
  &__nav{
    margin-top: auto;
    padding-top: 2em;
    display: flex;
  }
}

.popular{
  padding: 2em 0;
  &__top{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__nav{
    display: flex;
    flex-shrink: 0;
  }
  &__btn{
    margin-left: .75em;
  }
  &__grid{
    display: grid;
    grid-template-columns: repeat(4,calc(25% - 1.5em));
    gap: 4em 2em;
    margin-top: 4em;
  }
}

.info-block{
  &__subtitle{
    margin-bottom: .75em;
  }
  &__title{
    line-height: 100%;
    font-size: 2.75em;
  }
  &__desc{
    margin-top: 1em;
  }
}

.info-block2{
  @include grid100gap(1em);
  &__title{
    font-size: 3.5em;
    line-height: 114%;
  }
}

.worker{
  padding: 1em 0;
  .swiper{
    &-container{
      position: relative;
    }
    &-slide{
      background: #F9FAFB;
      border-radius: 1.5em;
      display: grid;
      grid-template-columns: 60.5% 39.5%;
    }
    &-pagination{
      width: unset;
      left: 4em;
      bottom: 4em;
    }
  }
  &__content{
    padding: 4em 4em 6.5em;
  }
  &__img{
    padding: .5625em 0;
    img{
      width: 100%;
      height: 29em;
      border-radius: 1em;
      object-fit: cover;
    }
  }
  &__icon{
    margin-bottom: 1.5em;
  }
  &__quote{
    font-size: 2.25em;
    line-height: 122%;
    letter-spacing: -0.02em;
    font-weight: 300;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp:4;
    -webkit-box-orient: vertical;
  }
  &__info{
    margin-top: 2em;
    @include grid100gap(.25em);
  }
}

.action{
  margin-top: 3em;
  padding: .75em 0;
  background-color: $blue;
  box-shadow: 0 1.5em 2em rgba(0, 0, 0, 0.2);
  &--black{
    background: #434F6A;
    .action__icon{
      background: #F9FAFB;
    }
    .action__btn{
      color: $defaultBlue;
    }
  }
  &__inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__left{
    display: flex;
    align-items: center;
  }
  &__icon{
    width: 3em;
    height: 3em;
    margin-right: 1em;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: $lightBlue;
    img{
      width: 1.5em;
    }
  }
  &__title{
    color: #FFFFFF;
    b{
      font-weight: 700;
    }
    br{
      display: none;
    }
  }
  &__btn{
    background-color: #F9F5FF;
  }
}

.online-catalog{
  padding: 3em 0 4em;
  background: #132E75;
  color: #fff;
  .info-block__desc{
    max-width: 32em;
  }
  &__grid{
    margin-top: 4em;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 4em;
  }
  &__icon{
    margin-bottom: 1em;
  }
  &__links{
    margin: .5em 0 1.25em;
    @include grid100gap(.525em);
  }
  &__link{
    &:hover{
      color: $lightBlue;
    }
  }
  &__btn{
    color: $lightBlue;
    &:hover,
    &:active,
    &:focus{
      color: #fff;
    }
  }
}

.advantages{
  padding: 3em 0 4em;
  &__inner{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 2em;
  }
}

.qa{
  padding: 6em 0;
  border-top: 1px solid #D0D5DD;
  &__inner{
    display: grid;
    grid-template-columns: 28em calc(100% - 32em);
    justify-content: space-between;
  }
  &__items{
    @include grid100gap(2em);
  }
}

.contacts{
  padding: 3em 0 4em;
  &__grid{
    display: grid;
    grid-template-columns: 21.75em calc(100% - 25.75em);
    justify-content: space-between;
    margin-top: 4em;
  }
  &__items{
    @include grid100gap(3em);
  }
  &__group{
    display: flex;
  }
  &__icon{
    flex-shrink: 0;
    margin-right: .75em;
  }
  &__title{
    height: 3.5em;
    display: flex;
    align-items: center;
    font-size: 1em;
  }
  &__desc{
    margin: -.2em 0 1.25em;
  }
}

.map{
  box-shadow: 0 .25em 1em rgba(0, 51, 153, 0.04), 0 .125em .125em rgba(0, 51, 153, 0.08);
  border-radius: 1em;
  width: 100%;
  height: 30.75em;
  min-height: 100%;
}


@media screen and (max-width: 992px){
  .main-search{
    padding: 1.5em 0;
    &__form{
      display: block;
    }
    &__left{
      gap: .25em;
    }
    &__hint{
      padding-right: 1em;
    }
    &__field{
      input{
        font-size: 1.25em;
        line-height: 200%;
        padding-bottom: .75em;
        height: 2.75em;
      }
    }
    &__btn{
      display: inline-flex;
      margin: 2em 0 0;
    }
  }

  .main-collection{
    padding: 2.5em 0;
    .swiper{
      &-container{
        width: calc(100% + .5em);
      }
      &-slide{
        width: 100%;
        padding-right: .5em;
      }
    }
  }

  .collection-card{
    height: 27.3125em;
    &__content{
      width: calc(100% - 2em);
    }
  }

  .popular{
    padding: 1.5em 0;
    &__top{
      display: block;
    }
    &__nav{
      margin: 2em 0 0;
      @include grid100gap(.75em);
    }
    &__btn{
      width: 100%;
      margin: 0;
    }
    &__grid{
      grid-template-columns: 100%;
      gap: 2.5em;
    }
  }

  .info-block{
    &__subtitle{
      font-size: .875em;
      margin-bottom: .86em;
    }
    &__title{
      font-size: 1.875em;
      line-height: 125%;
    }
    &__desc{
      margin-top: .89em;
    }
  }

  .info-block2{
    gap: 1.25em;
    &__title{
      font-size: 2em;
      line-height: 138%;
    }
  }

  .worker{
    .swiper{
      &-slide{
        grid-template-columns: 100%;
        gap: 4.875em;
        border-radius: 1em;
      }
      &-pagination{
        bottom: 31em;
        left: 1em;
      }
    }
    &__content{
      padding: 2em 1em 0;
    }
    &__quote{
      font-size: 1.75em;
      line-height: 135%;
      -webkit-line-clamp:6;
    }
  }

  .action{
    padding: 1.75em 0;
    &__inner{
      display: block;
    }
    &__left{
      display: block;
    }
    &__icon{
      margin-bottom: .75em;
    }
    &__title{
      max-width: 17em;
      b{
        display: block;
        margin-bottom: .125em;
      }
      br{
        display: unset;
      }
    }
    &__nav{
      margin-top: .75em;
    }
    &__btn{
      width: 100%;
    }
  }

  .online-catalog{
    padding: 4em 0;
    &__grid{
      grid-template-columns: 100%;
      gap: 2.5em;
      margin-top: 3em;
    }
    &__links{
      margin: .25em 0 1em;
    }
  }

  .advantages,
  .qa{
    padding: 4em 0;
    &__inner{
      grid-template-columns: 100%;
      gap: 2.5em;
    }
  }

  .contacts{
    &__grid{
      grid-template-columns: 100%;
      gap: 4em;
      margin-top: 2em;
    }
    &__items{
      gap: 1.5em;
    }
    &__title{
      height: 2.875em;
    }
  }

  .map{
    min-height: unset;
  }
}
