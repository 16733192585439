@import 'vars';
@import 'components/_text.scss';
@import 'components/_buttons.scss';
@import "components/_inputs.scss";
@import "components/_table.scss";
.swiper-pagination{
  &-bullet{
    width: .625em;
    height: .625em;
    margin-left: 0 !important;
    background-color: $lightBlue;
    opacity: 1;
    &:not(:last-child){
      margin-right: 1em !important;
    }
    &-active{
      background-color: $defaultBlue;
    }
  }
}

.grayBg{
  background-color: $grayBg;
}

.m-icon{
  width: 3.5em;
  height: 3.5em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $defaultBlue;
  border: .5em solid $blue;
  img{
    width: 1.5em;
  }
  &._light{
    background-color: rgba(170, 192, 249, 0.25);
    border-color: rgba(170, 192, 249, 0.12);
    border-width: .25em;
  }
}

.tabs{
  margin-top: .5em;
  display: flex;
  border-bottom: 1px solid #E4E7EC;
}

._tab{
  padding: 1em .25em;
  color: $grayText;
  font-weight: 500;
  flex-shrink: 0;
  position: relative;
  transition: all .3s;
  cursor: pointer;
  &:before{
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $defaultBlue;
    opacity: 0;
    transition: inherit;
  }
  span{
    font-size: .875em;
    line-height: 143%;
  }
  &:not(:last-child){
    margin-right: 1em;
  }
  &._active{
    pointer-events: none;
  }
  &._active,
  &:hover{
    color: $defaultBlue;
    &:before{
      opacity: 1;
    }
  }
  &-content{
    display: none;
    &._active{
      display: block;
    }
  }
}

.m-badge{
  padding: 0 .5em;
  display: inline-flex;
  align-items: center;
  height: 1.375em;
  border-radius: 1em;
  white-space: nowrap;
  font-weight: 500;
  &._blue{
    background: #EFF8FF;
    color: $blue;
  }
  &._green{
    background: #ECFDF3;
    color: #027A48;
  }
  &._yellow{
    background: #FFFAEB;
    color: #B54708;
  }
  &._red{
    background: #FFF6ED;
    color: #C4320A;
  }
  &._purple{
    background: #F9F5FF;
    color: #6941C6;
  }
  &._dark{
    background: #F8F9FC;
    color: #363F72;
  }
  &._pink{
    background: #FDF2FA;
    color: #C11574;
  }
  &._gray{
    background: #F2F4F7;
    color: #344054;
  }
  svg,
  img{
    margin-right: .25em;
    width: .75em;
    height: auto;
  }
}

.cartcalc{
  display: grid;
  grid-template-columns: 2.75em auto 2.75em;
  gap: .25em;
  width: 11em;
  input{
    text-align: center;
  }
  button{
    &:disabled{
      path{
        stroke: #667085;
      }
    }
  }
}

.cart-buttons{
  .cartcalc{
    display: none;
  }
  .cart-btn{
    &._added{
      display: none;
      &~.cartcalc{
        display: grid;
      }
    }
  }
}

.pagination{
  display: flex;
  align-items: center;
  &__item{
    padding: 0 .75em;
    min-width: 2.5em;
    border-radius: .5em;
    margin-right: .125em;
    &:hover,
    &._active{
      background: rgba(170, 192, 249, 0.12);
      color: $defaultBlue;
    }
  }
  &__next{
    color: $grayText;
  }
  &__mob{
    display: none;
  }
}


@media screen and (max-width: 992px){
  .m-icon{
    width: 2.875em;
    height: 2.875em;
    border-width: .375em !important;
    margin-bottom: .875em;
    img{
      width: 1.25em;
    }
  }

  .tabs{
    overflow-x: auto;
    @include fullScroll;
  }

  .pagination{
    display: none;
    &__mob{
      display: block;
      color: #344054;
    }
    &__next{
      span{
        display: none;
      }
    }
  }
}

