@import "../mixins";
.collection{
  padding: 2.25em 0 0;
  &__grid{
    margin-top: 1.25em;
    display: grid;
    grid-template-columns: repeat(4,calc(25% - 1.5em));
    gap: 2em;
  }
}

.product-info{
  padding: 1em;
  background: #FFFFFF;
  border: 1px solid #E4E7EC;
  box-shadow: 0 .25em .5em -.125em rgba(16, 24, 40, 0.1), 0 .125em .25em -.125em rgba(16, 24, 40, 0.06);
  border-radius: 8px;
  &__nums{
    display: flex;
    align-items: center;
    margin-bottom: 1.5em;
  }
  &__sale{
    margin-left: .625em;
    padding: 0 .625em;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    height: 1.5em;
    background: $red;
    border-radius: 1em;
    svg{
      width: .75em;
      height: auto;
      margin-left: .5em;
    }
  }
  &__price2{
    text-decoration-line: line-through;
    margin-left: .625em;
  }
  &__count{
    padding-left: 1px;
    margin-left: auto;
  }
}

.catalog-item{
  padding-bottom: 2.25em;
  overflow: hidden;
  @media screen and (min-width: 993px){
    .info-block2__title{
      font-size: 1.75em;
      font-weight: 600;
    }
    .info-block2__desc{
      display: none;
    }
    .info-block2__nav{
      display: flex;
    }
  }
  &__top{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__nav{
    flex-shrink: 0;
    display: flex;
    align-items: center;
    &>.m-btn{
      margin-left: .75em;
    }
    .cart-btn{
      padding: 0 1.4em;
    }
    .favourite-btn{
      padding: 0 .75em;
    }
  }
  &__tabs{
    margin-top: 2em;
  }
  &__content{
    margin-top: 2.25em;
  }
  .models__links{
    margin-top: 1.5em;
  }
}

.product-detail{
  &__top{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__inner{
    display: flex;
    margin-top: 1.25em;
  }
  @media screen and (min-width: 993px){
    .product-gallery{
      grid-template-columns: 5em 25em;
      &__small{
        order: -1;
      }
    }
  }
  &__content{
    flex-grow: 1;
    margin-left: 2em;
    max-width: 36.25em;
  }
  &__info{
    margin-top: 1em;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em 2em;
  }
  &__group{
    @include grid100gap(.25em);
  }
}

.brand{
  display: grid;
  grid-template-columns: 56.25em auto;
  gap: 1.5em 4.5em;
  align-items: center;
  &__info{
    @include grid100gap(2em);
  }
  &__img{
    img{
      width: 100%;
      display: block;
    }
  }
}

.brand-text{
  width: 56.25em;
  max-width: 100%;
  margin-top: 2em;
  padding-top: 3em;
  border-top: $border2;
  @include grid100gap(1.5em);
  img{
    display: block;
    width: 100%;
    margin-bottom: 1.5em;
  }
  .text18{
    max-width: 40em;
    p{
      line-height: inherit;
    }
  }
}


@media screen and (max-width: 992px){
  .collection{
    padding-top: 2.5em;
    .info-block__title{
      font-size: 2em;
    }
    &__grid{
      grid-template-columns: 100%;
      gap: 1.5em;
      margin-top: 1.5em;
    }
  }

  .catalog-item{
    &__top{
      display: block;
    }
    .info-block2{
      gap: .5em;
    }
    &__nav{
      margin: 1em 0 0;
      @include grid100gap(.75em);
      &>.m-btn{
        margin: 0;
      }
      .cartcalc{
        width: 100%;
        grid-template-columns: 5.5em auto 5.5em;
      }
    }
    &__content{
      margin-top: 1.5em;
    }
  }

  .product-detail{
    position: relative;
    padding-bottom: 5em;
    &__top{
      display: block;
    }
    &__inner{
      display: block;
      margin-top: 1.5em;
    }
    .product-gallery{
      &__big{
        .swiper-slide{
          padding: .625em .875em;
        }
        .product-gallery__img{
          height: 100%;
        }
      }
    }
    &__analog{
      width: 100%;
      height: 3em;
    }
    &__content{
      margin: 2em 0 0;
    }
    &__info{
      margin-top: 1em;
      gap: 1em;
    }
  }

  .product-info{
    margin-top: 1.5em;
    position: relative;
    &__nums{
      margin-bottom: 1em;
    }
    .cart-btn{
      width: calc(100% - 3em);
    }
    .favourite-btn{
      position: absolute;
      bottom: 1em;
      right: 1em;
    }
  }

  .brand{
    grid-template-columns: 100%;
    &__img{
      max-width: 25em;
      margin: 0 auto;
    }
    &__info{
      gap: 1em;
    }
  }

  .brand-text{
    padding-top: 1.5em;
    margin-top: 1.5em;
    gap: 1em;
    img{
      margin-bottom: 1em;
    }
  }
}
