.catalog-car{
  flex-grow: 1;
  padding-bottom: 1em;
}

.filter{
  margin-top: 1.5em;
  display: flex;
  align-items: center;
  &__field{
    width: 20em;
  }
  &__btn{
    margin-left: .5em;
  }
  &__own{
    margin-left: 3.25em;
  }
}

.models{
  margin-top: 3em;
  &__content{
    margin-top: 1.5em;
  }
  &__links{
    column-count: 5;
    column-gap: 2em;
  }
  &__more{
    width: 100%;
    margin-top: .75em;
    display: none;
  }
}

.model-link{
  display: inline-flex;
  width: 100%;
  align-items: center;
  &:not(:last-child){
    margin-bottom: .75em;
  }
  .link-icon{
    background-image: url(../img/chevron-right-blue.svg);
    margin-right: .75em;
  }
}

.link-icon{
  flex-shrink: 0;
  border: 1px solid $defaultBlue;
  border-radius: .375em;
  width: 1.25em;
  height: 1.25em;
  display: flex;
  align-items: center;
  justify-content: center;
  @include bgImgSize(.875em);
}

.model{
  margin-top: 3em;
  &__top{
    display: flex;
    align-items: center;
    .model-link{
      width: unset;
      margin-left: .5em;
    }
  }
  &__tags{
    display: flex;
    flex-wrap: wrap;
    margin-top: 1em;
    max-width: 60em;
  }
}

.tag{
  display: flex;
  align-items: center;
  padding: 0 .75em;
  margin: .5em .5em 0 0;
  height: 2.375em;
  flex-shrink: 0;
  color: $blue;
  border-radius: .375em;
  &:hover,
  &._active{
    background: rgba(170, 192, 249, 0.2);
    color: $black;
  }
  &._active{
    pointer-events: none;
    .tag__num{
      display: flex;
    }
  }
  &__num{
    font-size: .75em;
    padding: 0 .67em;
    height: 1.83em;
    display: none;
    align-items: center;
    background: rgba(170, 192, 249, 0.2);
    border-radius: 1.33em;
    margin-left: .67em;
  }
}


@media screen and (max-width: 992px){
  .filter{
    display: grid;
    grid-template-columns: auto 5.5625em;
    gap: 1.5em .5em;
    &__field{
      width: 100%;
    }
    &__btn{
      margin: 0;
    }
    &__own{
      grid-column: 1/-1;
      margin: 0;
    }
  }

  .models{
    margin-top: 2em;
    &__links{
      column-count: 1;
      &._cut{
        .model-link{
          &:nth-child(22)~.model-link{
            display: none;
          }
        }
        &~.models__more{
          display: flex;
        }
      }
    }
  }

  .model{
    margin-top: 2em;
  }

  .tag{
    &:not(._active){
      padding: 0;
    }
  }
}

