@import "../vars";
.m-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ease-out .225s;
    padding: 0;
    font-weight: 500;
    font-size: 1em;
    &:focus {
        box-shadow: none;
    }
    svg,
    img{
        display: block;
        width: 1.25em;
        height: auto;
        &:first-child{
            margin-right: .5em;
        }
        &:nth-child(2){
            margin-left: .5em;
        }
    }
}

.m-btn-primary{
    color: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: .5em;
    background-color: $blue;
    &:hover{
        color: #fff;
        background-color: $defaultBlue;
    }
    &:active,
    &:focus{
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 .25em #F4EBFF;
    }
    &:disabled{
        background-color: $lightBlue;
        pointer-events: none;
    }
}

.m-btn-secondary{
    color: $defaultBlue;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: .5em;
    background-color: $lightBlueTransparent;
    &:hover{
        color: $defaultBlue;
        background-color: #F4EBFF;
    }
    &:active,
    &:focus{
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 .25em #F4EBFF;
        background-color: $lightBlueTransparent;
    }
    &:disabled{
        background-color: #FCFAFF;
        color: $lightBlue;
        pointer-events: none;
    }
}

.m-btn-outline{
    color: #344054;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: .5em;
    border: 1px solid #D0D5DD;
    &:hover{
        color: #1D2939;
        background-color: #F9FAFB;
    }
    &:active,
    &:focus{
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 .25em #F4EBFF;
        background-color: transparent;
    }
    &:disabled{
        background-color: transparent;
        color: #D0D5DD;
        pointer-events: none;
        border-color: #E4E7EC;
    }
}

.m-btn-outline-blue{
    border-color: $lightBlue;
    color: $defaultBlue;
}

.m-btn-outline-blue2{
    border-color: $defaultBlue;
    color: $defaultBlue;
}

.m-btn-white{
    background-color: #fff;
    color: $grayText;
    border-radius: .5em;
    &:hover{
        background-color: #F9FAFB;
        color: #475467;
    }
    &:active,
    &:focus{
        background-color: #fff;
        color: $grayText;
    }
    &:disabled{
        color: #D0D5DD;
    }
}

.m-btn-2xl{
    padding: 0 1.75em;
    height: 3.75em;
    span{
        font-size: 1.125em;
    }
    svg,
    img{
        width: 1.5em;
        &:first-child{
            margin-right: .75em;
        }
        &:nth-child(2){
            margin-left: .75em;
        }
    }
}

.m-btn-xl{
    padding: 0 1.25em;
    height: 3em;
    span{
        font-size: 1em;
    }
    svg{
        width: 1.25em;
        &:first-child{
            margin-right: .5em;
        }
        &:nth-child(2){
            margin-left: .5em;
        }
    }
}

.m-btn-lg{
    padding: 0 1.125em;
    height: 2.75em;
    span{
        font-size: 1em;
    }
}

.m-btn-md{
    padding: 0 1em;
    height: 2.5em;
    span{
        font-size: .875em;
    }
}

.m-btn-sm{
    padding: 0 .875em;
    height: 2.25em;
    span{
        font-size: .875em;
    }
}

.m-btn-text{
    padding: 0;
    justify-content: unset;
    text-align: unset;
    height: unset;
    color: $defaultBlue;
    &:hover{
        color: #53389E;
    }
    &:active,
    &:focus{
        color: $defaultBlue;
    }
    &:disabled{
        color: #D0D5DD;
        pointer-events: none;
    }
}

.m-btn-text-white{
    color: #fff;
    &:hover{
        opacity: .9;
        color: #fff;
    }
    &:active,
    &:focus{
        color: #fff;
    }
}

.m-btn-icon{
    img,
    svg{
        margin: 0 !important;
    }
}

.swiper-button{
    width: 3em;
    height: 3em;
    font-size: 1em;
    border-radius: 50%;
    img{
        margin: 0 !important;
    }
}

.favourite-btn{
    padding: 0 .625em;
    &:hover,
    &._added{
        path{
            stroke: $defaultBlue;
            fill: $defaultBlue;
        }
    }
}

.cart-btn{
    padding: 0 .625em;
    &__text2{
        display: none;
    }
    &._added{
        path{
            fill: #fff;
        }
        .cart-btn__text1{
            display: none;
        }
        .cart-btn__text2{
            display: block;
        }
    }
}
