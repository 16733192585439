@import "../mixins";
.logo{
  width: 12em;
  display: block;
  flex-shrink: 0;
  img{
    width: 100%;
    display: block;
  }
}

.header{
  &__logo{
    margin-right: 1.875em;
  }
  &__links{
    display: flex;
    align-items: center;
  }
  &__link{
    color: $defaultBlue;
    margin-right: 2.5em;
    span{
      font-size: .875em;
      font-weight: 500;
      line-height: 143%;
    }
  }
  &__phone{
    display: flex;
    align-items: center;
    img{
      width: 1.25em;
      display: block;
      flex-shrink: 0;
      margin-right: .5em;
    }
  }
  &__action{
    display: flex;
    align-items: center;
    margin-left: auto;
    padding-left: 1em;
  }
  &__cart,
  &__login{
    margin-left: .75em;
  }
  &__reg{
    background-color: transparent;
  }
  &__email{
    margin-right: .25em;
  }
  &__logout{
    background-color: transparent;
    padding: 0;
    width: 2.25em;
  }
  &__garage{
    margin-left: .75em;
  }
}

.header-top{
  padding: .5em 0;
  background: #F9FAFB;
  border-bottom: 1px solid #DBE0EF;
  &__inner{
    display: flex;
    align-items: center;
    position: relative;
  }
}

.header-bottom{
  padding: 1.125em 0;
  border-bottom: 1px solid #F2F4F7;
  position: relative;
  &__inner{
    display: flex;
    align-items: center;
    position: relative;
  }
}

.header-search{
  margin-left: auto;
  &__show{
    border: 1px solid $defaultBlue;
    border-radius: .5em;
    display: flex;
  }
  &__btn,
  &__method{
    min-width: 7.0625em;
    height: 2.75em;
    padding: 0 .5em;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    color: $defaultBlue;
  }
  &__btn{
    cursor: pointer;
    border-right: 1px solid $defaultBlue;
    img{
      margin-right: .5em;
      width: 1.25em;
      display: block;
      flex-shrink: 0;
    }
  }
  &__method{
    position: relative;
    &-title{
      font-size: 1em;
      font-weight: 500;
    }
    .m-select{
      margin-left: .25em;
      position: static;
      .nice-select{
        position: static;
        &:before{
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
      .current{
        box-shadow: none !important;
        border: none;
        height: unset;
        background-color: transparent;
        padding: 0 1.75em 0 0;
        font-weight: 500;
        font-size: 1em;
        color: $defaultBlue;
        background-image: url(../img/chevron-down-blue.svg);
        background-position: right center;
      }
      .list{
        width: 14em;
        left: unset;
        right: 0;
        z-index: 10;
      }
    }
  }
  &__hidden{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: calc(100% - 13.875em);
    opacity: 0;
    pointer-events: none;
    transition: all .3s;
  }
}

._open-search{
  .header-search{
    &__show{
      opacity: 0;
      pointer-events: none;
    }
    &__hidden{
      opacity: 1;
      pointer-events: unset;
    }
  }
}

.search-block{
  display: flex;
  &__field{
    flex-grow: 1;
    position: relative;
    input{
      border-radius: 0 .5em .5em 0;
    }
  }
  &__btn{
    z-index: 2;
    width: 10em;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0 .5em .5em 0;
  }
  &__type{
    background-color: #fff;
    flex-shrink: 0;
    min-width: 8.375em;
    border: $border;
    border-right: none;
    border-radius: .5em 0 0 .5em;
    padding: 0 .75em;
    display: flex;
    align-items: center;
  }
  &__close{
    margin-left: .75em;
    width: 2.75em;
    padding: 0;
  }
}

.menu{
  display: flex;
  align-items: center;
  &__item{
    margin-right: 2em;
    &:last-child{
      margin-right: 0;
    }
    &._active{
      .menu__item-hidden{
        opacity: 1;
        pointer-events: unset;
      }
      .menu__item-show{
        img{
          transform: rotate(-180deg);
        }
      }
    }
    &-link,
    &-show{
      color: #2C3238;
      font-weight: 300;
      transition: all .3s;
      &:hover{
        cursor: pointer;
        color: $defaultBlue;
      }
    }
    &-show{
      display: flex;
      align-items: center;
      img{
        margin-left: .5em;
        width: 1.25em;
        display: block;
        flex-shrink: 0;
        transition: inherit;
      }
    }
    &-hidden{
      z-index: 10;
      position: absolute;
      left: 0;
      top: 100%;
      width: 100%;
      max-height: calc(100vh - 10em);
      overflow-y: auto;
      overflow-x: hidden;
      background: #FFFFFF;
      box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05);
      margin-top: 1px;
      @media screen and (min-width: 993px){
        opacity: 0;
        pointer-events: none;
        transition: all .3s;
      }
    }
    &._mob-show{
      @media screen and (min-width: 993px){
        display: none;
      }
    }
  }
  &__inner{
    padding: 2em 0;
    display: grid;
    grid-template-columns: calc(100% - 20.25em) 18.25em;
    gap: 2em;
  }
  &__left{
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1.5em;
  }
  &__right{
    padding-left: 1.25em;
    position: relative;
    &:before{
      content: '';
      position: absolute;
      left: 0;
      top: -2em;
      width: 10000px;
      height: calc(100% + 4em);
      background: #F9FAFB;
    }
  }
  &__groups{
    margin-top: .75em;
    &--2{
      column-count: 2;
      column-gap: 1.5em;
    }
  }
}

.menu-card{
  display: flex;
  padding: .75em;
  margin-bottom: .5em;
  &__icon{
    width: 1.5em;
    flex-shrink: 0;
    margin-right: 1em;
    img{
      width: 100%;
    }
  }
  &__content{
    @include grid100gap(.25em)
  }
}

.menu-hint{
  padding: .75em;
  position: relative;
  z-index: 2;
  &__img{
    position: relative;
    padding-bottom: 57%;
    margin-bottom: 1.5em;
    img{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: .375em;
    }
  }
  &__info{
    @include grid100gap(.25em)
  }
  &__nav{
    display: flex;
    align-items: center;
    margin-top: .75em;
  }
  &__btn{
    margin-right: .75em;
  }
}

.burger{
  display: none;
}

@media screen and (max-width: 992px){
  .header{
    &__action{
      padding: 0;
    }
    &__links,
    &__cart,
    &__garage{
      display: none;
    }
    &__email{
      margin: 0;
    }
    &__logout,
    &__login{
      margin-left: .5em;
      background-color: $lightBlueTransparent;
    }
    &__login{
      width: 2.25em;
      span{
        display: none;
      }
      img{
        margin: 0 !important;
      }
    }
    &._open{
      .menu{
        opacity: 1;
        pointer-events: unset;
      }
      .burger{
        background-image: url(../img/burger2.svg);
      }
    }
  }

  .header-top{
    &__inner{
      justify-content: space-between;
    }
  }

  .header-bottom{
    padding: 1em 0;
    &__inner{
      justify-content: space-between;
    }
  }

  .header-search{
    display: none;
  }

  .burger{
    display: flex;
    padding: 0;
    width: 2.25em;
    background-image: url(../img/burger.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1.25em auto;
  }

  .menu{
    position: fixed;
    left: 0;
    top: 7.5em;
    background-color: #fff;
    display: block;
    padding: 0 0 4em;
    opacity: 0;
    pointer-events: none;
    transition: all .3s;
    width: 100%;
    height: calc(100vh - 7.5em);
    overflow-y: auto;
    z-index: 100;
    &__item{
      &:not(:last-child){
        margin: 0 0 .5em;
      }
      &-show,
      &-link{
        display: flex;
        padding: .75em .25em .75em 1em;
        justify-content: space-between;
      }
      &-hidden{
        position: static;
        height: unset;
        max-height: unset;
        overflow: unset;
        box-shadow: none;
        background-color: transparent;
        display: none;
      }
    }
    &__inner{
      display: block;
      padding: 1em 0 0;
    }
    &__left{
      grid-template-columns: 100%;
      gap: 1.5em;
    }
    &__right{
      padding: 1.25em 0;
      &:before{
        top: 0;
        height: 100%;
        width: calc(100% + 2em);
        left: -1em;
      }
    }
    &__groups{
      column-count: 1;
    }
  }

  .menu-card{
    padding-left: 0;
    padding-right: 0;
    align-items: center;
    &__desc{
      display: none;
    }
  }

  .menu-hint{
    &__img{
      //padding-bottom: 34%;
    }
  }
}
