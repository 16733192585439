@import "../mixins";
.garage{
  &__subtitle{
    margin-top: 1.33em;
  }
  &__items{
    @include grid2;
  }
}

.garage-form{
  @include grid3;
  margin-top: 1.5em;
  max-width: 63em;
  &__13{
    grid-column: 1/3;
  }
  &__year{
    width: 50%;
  }
  &__photo{
    .file-input{
      margin-top: 1em;
    }
  }
  &__nav{
    display: flex;
    grid-column: 1/-1;
    .m-btn{
      margin-right: .75em;
    }
  }
}

.car-card{
  background-color: #fff;
  border: $border2;
  box-shadow: $shadow;
  border-radius: 1em;
  display: flex;
  flex-direction: column;
  &__top{
    display: flex;
    align-items: center;
    padding: 2em 2em 1.5em;
  }
  &__img{
    background-color: #F2F4F7;
    background-image: url(../img/camera.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1.75em auto;
    width: 3.5em;
    height: 3.5em;
    border-radius: 50%;
    flex-shrink: 0;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  &__info{
    flex-grow: 1;
    margin: 0 .75em;
    @include grid100gap(.25em);
  }
  &__name{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .m-badge{
      display: inline-flex;
    }
    .text24{
      margin-right: .33em;
    }
  }
  &__buttons{
    display: flex;
    align-items: center;
    margin-left: auto;
    flex-shrink: 0;
    .m-btn{
      padding: 0;
      width: 2.5em;
      margin-left: .125em;
    }
  }
  &__content{
    padding: 2em 2em 1.5em;
    border-top: $border2;
  }
  &__info2{
    @include grid100gap(.25em);
  }
  &__items{
    margin-top: 1.5em;
    column-count: 2;
    column-gap: 1.5em;
  }
  &__item{
    width: 100%;
    display: inline-flex;
    align-items: center;
    color: $defaultBlue;
    margin-bottom: 1em;
    &:before{
      content: '';
      width: 1.5em;
      height: 1.5em;
      background-image: url(../img/book.svg);
      @include bgImgSize(100%);
      flex-shrink: 0;
      margin-right: .75em;
    }
    &._light{
      color: $blue;
      &:before{
        background-image: url(../img/book2.svg);
      }
    }
  }
  &__nav{
    margin-top: auto;
    padding: 2em;
    display: grid;
    grid-template-columns: repeat(2,calc(50% - .375em));
    gap: .75em;
    border-top: $border2;
  }
}


@media screen and (max-width: 992px){
  .garage{
    &__subtitle{
      margin-top: 1.77em;
    }
  }

  .garage-form{
    &__13{
      grid-column: 1/-1;
    }
    &__photo{
      display: grid;
      grid-template-columns: auto 2.75em;
      gap: .75em;
      align-items: flex-end;
      .file-input{
        padding: 0;
        height: 2.75em;
        span{
          display: none;
        }
        img{
          margin: 0 !important;
        }
      }
    }
    &__nav{
      @include grid100gap(.75em);
      .m-btn{
        margin-right: 0;
      }
    }
  }

  .car-card{
    &__top{
      padding: 1em 1em 1.5em;
      flex-wrap: wrap;
    }
    &__info{
      width: 100%;
      margin: 1em 0 0;
      order: 2;
      gap: .5em;
      padding-bottom: 2em;
      position: relative;
      .m-badge{
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
    &__content{
      padding: 1em 1em 1.5em;
    }
    &__items{
      column-count: 1;
    }
    &__nav{
      padding: 1em;
      grid-template-columns: 100%;
    }
  }
}
