@import "../mixins";

.m-table{
  background: #FFFFFF;
  table{
    min-width: 100%;
    border-spacing: 0;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: .5em;
  }
  th,td{
    padding: 0 1.25em 0 1.5em;
    text-align: left;
    @media screen and (min-width: 993px){
      border-top: $border;
      &:first-child{
        border-left: $border;
      }
      &:last-child{
        border-right: $border;
      }
    }
  }
  tbody{
    tr{
      &:last-child{
        @media screen and (min-width: 993px){
          td{
            border-bottom: $border;
            &:first-child{
              border-radius: 0 0 0 .5em;
            }
            &:last-child{
              border-radius: 0 0 .5em 0;
            }
          }
        }
      }
    }
  }
  th{
    height: 2.75em;
    font-weight: 500;
    background: #F9FAFB;
    &:first-child{
      border-radius: .5em 0 0 0;
    }
    &:last-child{
      border-radius: 0 .5em 0 0;
    }
  }
  td{
    height: 3.875em;
  }
  &__sort{
    display: flex;
    align-items: center;
    cursor: pointer;
    img{
      margin-left: .25em;
      width: 1em;
      display: block;
      transition: all .3s;
    }
    &._active{
      img{
        transform: rotate(-180deg);
      }
    }
  }
  &__title{
    display: none;
  }
}

.model-table{
  margin-top: 1.5em;
  th,
  td{
    &:nth-child(1){
      width: 11.5em;
    }
    &:nth-child(2){
      max-width: 7.875em;
    }
    &:nth-child(3){
      padding-right: 0;
    }
    &:last-child{
      padding-right: 1em;
    }
  }
  th{
    &:nth-child(2){
      padding-right: 0;
    }
  }
  td{
    &:nth-child(2){
      padding-right: 1.75em;
      .m-table__desc>*{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
      }
    }
  }
}


@media screen and (max-width: 992px){
  .m-table{
    background-color: transparent;
    table{
      display: block;
      box-shadow: none;
      background-color: transparent;
    }
    thead{
      display: none;
    }
    tbody{
      @include grid100gap(1.5em);
    }
    tr{
      display: block;
    }
    td{
      display: grid;
      grid-template-columns: 41.5% 58.5%;
      padding: 0 !important;
      width: 100% !important;
      max-width: 100% !important;
      height: unset !important;
      min-height: 2.75em;
      border-top: $border;
      border-left: $border;
      border-right: $border;
      &:first-child{
        border-radius: .25em .25em 0 0;
        .m-table__title{
          border-radius: .25em 0 0 0;
        }
        .m-table__desc{
          border-radius: 0 .25em 0 0;
        }
      }
      &:last-child{
        border-bottom: $border;
        border-radius: 0 0 .25em .25em;
        .m-table__title{
          border-radius: 0 0 0 .25em;
        }
        .m-table__desc{
          border-radius: 0 0 .25em 0;
        }
      }
    }
    &__title{
      background: #F9FAFB;
      display: flex;
      align-items: center;
      padding: .625em .625em .625em 1.5em;
    }
    &__desc{
      display: flex;
      align-items: center;
      padding: .625em 1.5em;
      border-left: $border;
      min-height: 100%;
    }
  }
}
