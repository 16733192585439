.contacts-page{
  .contacts__grid{
    margin-top: 0;
  }
  &__tabs{
    margin-bottom: 1.5em;
  }
}

@media screen and (max-width: 992px){
  .contacts-page{
    padding-top: .5em;
  }
}
