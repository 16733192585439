@import 'vars';
html {
    width: 100%;
    font-size: unquote("min(100vw, 1280px)");
}

.wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
}

.content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

body {
    font-family: $font1;
    font-weight: 400;
    color: $black;
    font-size: 1.25%;
    &.fixed-body {
        overflow-y: hidden;
    }
}

.container {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 2em;
    @media screen and (max-width: 992px) {
        padding: 0 1em;
    }
}

a {
    &:hover {
        color: $defaultBlue;
    }
}

@media screen and (max-width: 992px){
    body{
        font-size: 4.45%;
    }
}
