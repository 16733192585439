.favourite{
  &__top{
    display: flex;
    align-items: center;
    .m-badge{
      margin-left: 1em;
    }
  }
  &__code{
    margin-left: .5em;
  }
}
