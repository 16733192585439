.catalog-list{
  padding-bottom: 5em;
  &__images{
    display: grid;
    grid-template-columns: repeat(8,1fr);
    gap: 2em;
    margin-top: 3em;
  }
  &__names{
    column-count: 5;
    column-gap: 2em;
    margin-top: 4em;
  }
  &__name{
    display: inline-block;
    width: 100%;
    margin-bottom: .75em;
    color: $grayText;
    line-height: 150%;
  }
  &__nav{
    display: flex;
  }
}

.catalog-img{
  display: block;
  &__wrap{
    position: relative;
    padding-bottom: 100%;
    display: block;
  }
  img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    max-width: 100%;
    max-height: 100%;
  }
}


@media screen and (max-width: 992px){
  .catalog-list{
    padding-bottom: 4em;
    &__images{
      grid-template-columns: repeat(2,1fr);
      gap: 0 1.5em;
      margin-top: 2em;
    }
    &__names{
      column-count: 2;
      column-gap: 1em;
      margin-top: 1.5em;
      position: relative;
      padding-bottom: 3.75em;
    }
    &__name{
      margin-bottom: 1em;
    }
    &__nav{
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
    &__more{
      width: 100%;
    }
  }
}
