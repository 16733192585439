@import "../mixins";
.part{
  margin-top: 1.5em;
  &__title{
    @media screen and (min-width: 993px){
      br{
        display: none;
      }
    }
  }
}

.catalog-filter{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.5em;
  .sort-select{
    .current{
      color: $black !important;
    }
  }
}



.part-table{
  margin-top: 1.5em;
  &__name{
    display: flex;
    align-items: center;
  }
  &__camera{
    @include center;
    width: 2.5em;
    height: 2.5em;
    border-radius: 50%;
    background: #F2F4F7;
    margin-left: .75em;
    img{
      width: 1.25em;
    }
  }
  &__action{
    display: flex;
    align-items: center;
    .favourite-btn{
      margin-left: .75em;
    }
  }
  &__btn{
    width: 8.5625em;
  }
  th,td{
    &:nth-child(1){
      width: 15em;
    }
    &:nth-child(2){
      width: 22.75em;
    }
    &:nth-child(3){
      width: 7.5em;
    }
    &:nth-child(4){
      width: 10.5em;
    }
    &:last-child{
      width: 14.75em;
    }
  }
}


@media screen and (max-width: 992px){
  .part{
    margin-top: 2em;
    &:not(:last-child){
      margin-bottom: 3em;
    }
  }

  .catalog-filter{
    @include grid100gap(1.875em);
  }

  .part-table{
    td{
      min-height: 3.75em;
      grid-template-columns: 29.3% 70.7%;
    }
    .m-table{
      &__title,
      &__desc{
        padding: 1em;
      }
    }
  }
}
