@import "../mixins";
.catalog-parts,
.catalog-part{
  padding-bottom: 1em;
  flex-grow: 1;
}

.car-info{
  padding: 1em 2em 2em;
  background: #FFFFFF;
  border: 1px solid #E4E7EC;
  box-shadow: 0 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  border-radius: .5em;
  margin-top: 1.5em;
  width: calc(100% + 4em);
  margin-left: -2em;
  &__grid{
    margin-top: 1em;
    column-count: 4;
    column-gap: 1.5em;
  }
  &__group{
    display: inline-block;
    width: 100%;
    &:not(:last-child){
      margin-bottom: 1em;
    }
    .text14{
      line-height: 143%;
    }
    .text16{
      line-height: 188%;
      margin-top: .5em;
    }
  }
}

.catalog-part{
  &__wrap{
    display: grid;
    grid-template-columns: auto 42.75em;
    justify-content: space-between;
    margin-top: 1.5em;
    gap: 1em;
  }
  &__items{
    @include grid100gap(.5em);
  }
  &__img{
    img{
      width: 100%;
    }
  }
}

.part-card2{
  background: #FFFFFF;
  border: 1px solid #E4E7EC;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  border-radius: .5em;
  padding: .5em 1em;
  &__title{
    b{
      font-weight: 700;
    }
  }
  &__table{
    padding-top: 1.5em;
    background-color: transparent;
    table{
      box-shadow: none;
    }
    th,td{
      padding: 0 .5em;
    }
    td{
      height: 2.5em;
    }
  }
}


@media screen and (max-width: 992px){
  .catalog-parts{
    .info-block2__title{
      letter-spacing: -0.02em;
    }
  }

  .car-info{
    margin-top: 2em;
    padding: 1em;
    width: 100%;
    margin-left: 0;
    &__grid{
      column-count: 1;
    }
  }

  .catalog-part{
    &__wrap{
      grid-template-columns: 100%;
      gap: 1.5em;
      margin-top: 2em;
    }
    &__right{
      order: -1;
    }
    &__items{
      @include grid100gap(.5em);
    }
  }

  .part-card2{
    &__table{

    }
    .m-table{
      td{
        grid-template-columns: 43.5% 56.5%;
      }
      &__title,
      &__desc{
        padding-left: .5em;
        padding-right: .5em;
      }
    }
  }
}
