@import "../mixins";
.catalog{
  &__inner{
    margin-top: 1.5em;
    display: grid;
    grid-template-columns: 17.5em calc(100% - 21.5em);
    gap: 4em;
  }
  &__nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__filter-toggle{
    display: none;
  }
  &__count{
    display: flex;
    align-items: center;
    .fg{
      min-width: 5.375em;
      margin-left: .5em;
    }
    .current{
      color: $defaultBlue;
      font-weight: 500;
    }
  }
  &__grid{
    display: grid;
    grid-template-columns: repeat(3,calc(100% / 3 - (4em / 3)));
    gap: 4em 2em;
    margin-top: 4em;
  }
  &__bottom{
    padding-top: 1.25em;
    margin-top: 4em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #E4E7EC;
  }
}

.sort-select,
.by-select{
  display: flex;
  align-items: center;
  .m-select{
    margin-left: .25em;
    .nice-select{
      &:before{
        content: '';
        position: absolute;
        top: 0;
        right: 100%;
        width: 8em;
        height: 100%;
      }
    }
    .current{
      padding: 0;
      height: unset;
      border: none;
      background-image: none !important;
      color: $lightBlue;
      background-color: transparent;
      box-shadow: none !important;
      text-transform: lowercase;
    }
  }
}

.sort-select{
  &__icon{
    flex-shrink: 0;
    display: block;
    width: 1.25em;
    margin-right: .5em;
  }
  .m-select{

  }
}

.by-select{
  &__wrap{
    display: flex;
    align-items: center;
    padding: 0 1.25em;
    height: 3em;
    position: relative;
    margin-left: .5em;
  }
  .m-select{
    position: static;
    .nice-select{
      position: static !important;
      &:before{
        width: 100%;
        right: 0;
      }
    }
    .current{
      color: $defaultBlue !important;
    }
  }
}

.filter2{
  padding: 1.5em;
  background: #F9FAFB;
  box-shadow: 0 1.25em 1.5em -.25em rgba(16, 24, 40, 0.1), 0 .5em .5em -.25em rgba(16, 24, 40, 0.04);
  border-radius: 1em;
  &__top{
    @include grid100gap(.25em);
  }
  &__groups{
    margin-top: 1.5em;
    @include grid100gap(1.5em);
  }
  &__checks{
    @include grid100gap(.75em);
    margin-top: 1em;
    padding-left: .5em;
    &--col2{
      grid-template-columns: 1fr 1fr;
    }
    &-more{
      display: none;
      grid-column: 1/-1;
      ._view2{
        display: none;
      }
    }
    &._more:not(._show){
      .m-check:nth-child(8) ~ .m-check{
        display: none;
      }
    }
    &._more{
      .filter2__checks-more{
        display: block;
      }
    }
    &._show{
      .filter2__checks-more{
        ._view1{
          display: none;
        }
        ._view2{
          display: unset;
        }
      }
    }
    .m-badge{
      height: 1.5em;
      padding: 0 .625em;
      font-size: 1.14em;
    }
  }
  &__field{
    margin-top: 1em;
    width: 100%;
  }
  &__mob-top,
  &__bottom,
  &__bg,
  &__close{
    display: none;
  }
  &__slider{
    margin-top: 1em;
    &-inputs{
      display: grid;
      grid-template-columns: 6.25em auto 6.25em;
    }
    &-split{
      text-align: center;
      height: 2.75em;
      align-self: flex-end;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .range-slider{
      margin-top: 1.5em;
      background: #E4E7EC;
      border: none;
      height: .5em;
      border-radius: .25em;
    }
    .noUi-connect{
      background: $defaultBlue;
    }
    .noUi-handle{
      border: none;
      border-radius: 50%;
      width: 1.5em;
      height: 1.5em;
      background: #FFFFFF;
      box-shadow: 0 .25em .5em -.125em rgba(16, 24, 40, 0.1), 0 .125em .25em -.125em rgba(16, 24, 40, 0.06);
      top: -.5em;
      &:before,
      &:after{
        display: none;
      }
    }
  }
}

.selected-filters{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.selected-filter{
  margin: .25em .5em 0 0;
  padding: 0 .625em;
  height: 1.5em;
  display: flex;
  align-items: center;
  background: #F2F4F7;
  border-radius: 1em;
  font-weight: 500;
  color: #344054;

  span{
    font-size: .875em;
  }
  &__remove{
    width: .75em;
    margin-left: .5em;
    flex-shrink: 0;
    svg{
      width: 100%;
      height: auto;
    }
    &:hover{
      path{
        stroke:$defaultBlue;
      }
    }
  }
}

.product-card{
  padding-top: 2.5em;
  position: relative;
  display: flex;
  flex-direction: column;
  .favourite-btn{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  &__img{
    padding-bottom: 95%;
    position: relative;
    display: block;
    img{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      max-width: 100%;
      max-height: 100%;
    }
  }
  &__badges{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 1.25em;
    .m-badge{
      margin: .25em .25em 0 0;
    }
  }
  &__by{
    margin-top: .75em;
    display: block;
  }
  &__title{
    display: block;
    margin-top: .125em;
  }
  &__prices{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &__price{
    white-space: nowrap;
    margin-top: .75em;
  }
  &__nav{
    width: 100%;
    margin-top: auto;
    padding-top: 1em;
    .cartcalc{
      max-width: 100%;
    }
    .cart-btn{
      padding: 0 1.25em;
    }
  }
}


@media screen and (max-width: 992px){
  .catalog{
    padding-bottom: 1em;
    &__inner{
      margin-top: 2em;
      grid-template-columns: 100%;
      gap: 0;
    }
    &__filter-toggle{
      display: flex;
      padding: 0;
      width: 2.5em;
    }
    &__grid{
      margin-top: 2em;
      grid-template-columns: 100%;
      gap: 1.5em;
    }
    &__bottom{
      padding-top: .5em;
      margin-top: 1.5em;
    }
    &__nav{
      flex-wrap: wrap;
    }
    &__sort{
      order: 2;
      width: 100%;
      margin-top: 1.875em;
    }
  }

  .sort-select{
    position: relative;
    .m-select,
    .nice-select{
      position: static !important;
    }
  }

  .by-select{
    display: block;
    &__wrap{
      margin: .5em 0 0;
      justify-content: center;
    }
  }

  .filter2{
    transition: all .3s;
    opacity: 0;
    pointer-events: none;
    bottom: 0;
    left: 0;
    height: calc(100vh - 3em);
    width: 100%;
    position: fixed;
    z-index: 100;
    box-shadow: 0px 12px 32px rgba(0, 51, 153, 0.12), 0px 8px 20px rgba(0, 51, 153, 0.08);
    border-radius: 1em 1em 0 0;
    overflow-y: auto;
    padding: 3.875em 1em 6.5em;
    &._active{
      opacity: 1;
      pointer-events: unset;
      &~.filter2__bg{
        display: block;
      }
    }
    &__mob-top{
      display: grid;
      gap: .5em;
      margin-bottom: 1.5em;
    }
    &__bg{
      position: fixed;
      z-index: 99;
      width: 100%;
      height: 100vh;
      top: 0;
      left: 0;
      background: rgba(102, 112, 133, 0.6);
    }
    &__close{
      display: flex;
      position: absolute;
      width: 1.5em;
      right: 1.5em;
      top: 1.5em;
      background-color: transparent;
      img{
        width: 100%;
      }
    }
    &__bottom{
      position: fixed;
      left: 0;
      bottom: 0;
      padding: .75em 1em;
      background: #FFFFFF;
      box-shadow: 0px -4px 16px rgba(0, 51, 153, 0.04), 0px -2px 2px rgba(0, 51, 153, 0.08);
      z-index: 10;
      display: grid;
      grid-template-columns: repeat(2,calc(50% - .375em));
      gap: .75em;
      width: 100%;
    }
  }

  .product-card{
    &__nav{
      display: grid;
      grid-template-columns: 100%;
      .cartcalc{
        width: 100%;
      }
    }
  }
}
