@import "../mixins";
.about{
  .info-block2{
    max-width: 65em;
  }
  &__info{
    @include grid100gap(.75em);
  }
  &__text{
    margin-top: 2em;
    column-count: 2;
    column-gap: 4em;
    p{
      line-height: inherit;
      &:not(:last-child){
        margin-bottom: .75em;
      }
    }
  }
}

.about-history{
  margin-top: 3em;
}

.about-goal{
  margin-top: 6em;
  &__wrap{
    margin-top: .75em;
    display: grid;
    grid-template-columns: calc(100% - 26em) 24em;
    gap: 2em;
  }
  &__title{
    letter-spacing: -0.02em;
  }
}

.about-nums{
  margin-top: 6em;
  @include grid3;
  padding: 4em;
  background: rgba(170, 192, 249, 0.2);
  border-radius: 1em;
  &__info{
    text-align: center;
    @include grid100gap(.75em);
  }
  &__title{
    line-height: 120%;
    letter-spacing: -0.02em;
  }
}

.about-offer{
  margin-top: 6em;
  .catalog-list__images,
  .about__text{
    margin-top: 2em;
  }
}

.cooperate{
  &__inner{
    padding: 4em;
    background: rgba(170, 192, 249, 0.2);
    border-radius: 1em;
  }
  .info-block{
    text-align: center;
    max-width: 48em;
    margin: 0 auto;
  }
  &__form{
    display: grid;
    grid-template-columns: 22.5em auto;
    gap: .5em .75em;
    width: 30.875em;
    max-width: 100%;
    margin: 2em auto 0;
    .fg{
      width: 22.5em;
      max-width: 100%;
      input{
        height: 3em;
      }
    }
  }
  &__hint{
    width: 100%;
    order: 2;
    grid-column: 1/-1;
    a{
      text-decoration: underline;
    }
  }
}


@media screen and (max-width: 992px){
  .about{
    &__text{
      column-count: 1;
    }
  }

  .about-history,
  .about-offer{
    .info-block__title{
      font-size: 1.5em;
      line-height: 158%;
      font-weight: 600;
    }
  }

  .about-history{
    margin-top: 4.5em;
  }

  .about-goal{
    margin-top: 3em;
    &__wrap{
      grid-template-columns: 100%;
      gap: 1em;
    }
    &__title{
      font-size: 1.5em;
      line-height: 158%;
    }
  }

  .about-nums{
    grid-template-columns: 100%;
    gap: 2em;
    padding: 4em 1em;
    margin-top: 1.5em;
    &__title{
      font-size: 3.75em;
    }
  }

  .about-offer{
    .catalog-list__images{
      margin-top: 1.25em;
    }
  }

  .cooperate{
    padding-bottom: 4em;
    &__inner{
      padding: 2.5em 1.5em;
    }
    .info-block__title{
      font-weight: 600;
    }
    &__form{
      grid-template-columns: 100%;
    }
    &__hint{
      order: unset;
      margin-bottom: .5em;
    }
  }
}
