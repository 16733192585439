@import "../mixins";
.order{
  padding-bottom: 6em;
}

.order-table{
  margin-top: 1.5em;
  table{
    margin-top: 1.5em;
  }
  th,
  td{
    padding: 0 0 0 1.5em;
    &:nth-child(1){
      width: 12.5em;
    }
    &:nth-child(2){
      width: 16.75em;
    }
    &:nth-child(3),
    &:nth-child(8){
      width: 7.5em;
    }
    &:last-child{
      width: 9.75em;
      padding-right: 1.5em;
    }
  }
}

.order-detail{
  background: #FFFFFF;
  border: 1px solid #E4E7EC;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05);
  border-radius: 1em;
  margin-top: 1.5em;
  &__top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.25em 2em 1.5em;
  }
  &__info,
  &__info2{
    @include grid100gap(.25em);
  }
  &__buttons{
    display: flex;
    align-items: center;
    .m-btn{
      width: 2.5em;
      padding: 0;
      margin-left: .125em;
      &:hover{
        path{
          stroke: $defaultBlue;
        }
      }
    }
  }
  &__form1{
    padding: 2em 2em 2.875em;
    border-top: 1px solid #E4E7EC;
    display: grid;
    grid-template-columns: repeat(2,calc(50% - 1.75em));
    justify-content: space-between;
    gap: 1.5em;
  }
  &__address{
    margin-top: 1.5em;
    &-new{
      margin-top: 1.5em;
    }
  }
  &__form2{
    padding: 2em;
    border-top: 1px solid #E4E7EC;
  }
  &__radios,
  &__customer{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  &__radios{
    .m-radio{
      &:not(:last-child){
        margin-right: 1.5em;
      }
    }
  }
  &__customer{
    margin-top: 1em;
    .fg{
      width: 20em;
      max-width: 100%;
      &:not(:last-child){
        margin-right: .75em;
      }
    }
  }
  &__nav{
    display: flex;
    align-items: center;
    margin-top: 4em;
    &:first-child{
      margin-top: 0;
    }
    .m-btn{
      margin: 0 .75em 0 0;
    }
    ._view2{
      display: none;
    }
  }
}


@media screen and (max-width: 992px){
  .order{
    padding-bottom: 4em;
  }

  .order-table{
    margin-top: 2em;
    table{
      margin-top: 2em;
    }
    td{
      grid-template-columns: 50% 50%;
    }
    .m-table{
      &__title,
      &__desc{
        padding: .375em 1em;
      }
    }
  }

  .order-detail{
    margin-top: 1.5em;
    &__top{
      padding: 1.25em 1em 1.5em;
      display: block;
    }
    &__buttons{
      margin-top: 1.5em;
      .m-btn{
        margin: 0 .125em 0 0;
      }
    }
    &__form1{
      grid-template-columns: 100%;
      padding: 1em 1em 1.5em;
    }
    &__form2{
      padding: 1em 1em 1.5em;
    }
    &__radios,
    &__customer{
      display: block;
      .m-radio,
      .fg{
        &:not(:last-child){
          margin: 0 0 1em;
        }
      }
    }
    &__customer{
      .fg{
        width: 100%;
      }
    }
    &__nav{
      display: grid;
      grid-template-columns: 100%;
      gap: .75em;
      margin-top: 3em;
      .m-btn{
        padding: 0 .5em;
        margin: 0;
      }
      ._view1{
        display: none;
      }
      ._view2{
        display: unset;
      }
      span{
        //font-size: .75em;
      }
    }
  }
}
