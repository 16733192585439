.search{
  padding-bottom: 3em;
  &__progress{
    margin: 7.5em auto 0;
    width: 17.5em;
    max-width: 100%;
    position: relative;
    svg{
      width: 100%;
      height: auto;
    }
    circle{
      transition: all ease .3s;
    }
    &-num{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }
  .info-block2__title{
    word-break: break-word;
  }
  &__title{
    margin: 1.5em 0;
    font-size: 1em;
    display: flex;
    align-items: center;
    img{
      width: 2.25em;
      flex-shrink: 0;
      display: block;
      margin-right: .5em;
    }
  }
}

.circle-chart{
  transform: rotate(-90deg);
  &__fill{

  }
}
