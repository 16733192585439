@import "../mixins";
.requisite{
  padding-bottom: 4em;
  &__items{
    @include grid2;
    margin-top: 3em;
  }
}

.requisite-card{
  border: $border2;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  background-color: #fff;
  padding: 1em 2em 2em;
  border-radius: 1em;
  &__items{
    margin-top: 1em;
    @include grid100gap(1em);
  }
  &__group{
    @include grid100gap(.25em);
    .text16{
      line-height: 188%;
    }
  }
  &__nav{
    display: flex;
    align-items: center;
    margin-top: 1em;
    .m-btn{
      margin-right: .75em;
    }
  }
}


@media screen and (max-width: 992px){
  .requisite{
    .info-block2{
      gap: .5em;
    }
  }

  .requisite-card{
    padding: 1em 1em 2em;
    &__nav{
      display: grid;
      grid-template-columns: 100%;
      gap: .75em;
      .m-btn{
        margin: 0;
      }
    }
  }
}
