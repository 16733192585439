.cart{
  padding-bottom: 6em;
  &__nav{
    display: flex;
    margin-top: 1.5em;
  }
  &__delete{
    margin-left: .5em;
  }
  &__save{
    margin-left: auto;
  }
}

.cart-table{
  margin-top: 1.5em;
  tr:not(.cart-table__all),
  thead{
    th,td{
      &:nth-child(1){
        width: 12.5em;
      }
      &:nth-child(2){
        width: 15.25em;
      }
      &:nth-child(3){
        width: 7.5em;
      }
      &:nth-child(4){
        width: 11em;
        padding: 0 1.5em;
      }
      &:nth-child(6),
      &:nth-child(7){
        text-align: right;
      }
      &:last-child{
        width: 2.25em;
        padding: 0 1.5em;
      }
    }
  }
  th,td{
    padding: 0 0 0 1.5em;
  }
  td{
    height: 4.5em;
  }
  &__check-all{
    display: flex;
    align-items: center;
    .m-check{
      margin-right: .75em;
    }
  }
  &__all{
    td{
      &:nth-child(2),
      &:nth-child(3){
        text-align: right;
      }
    }
  }
  &__all2{
    td{
      border-top: none !important;
      height: 7em;
      border-radius: 0 0 .5em .5em !important;
    }
  }
  &__name{
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }
  &__check{
    margin-right: .75em;
  }
  .cartcalc{
    grid-template-columns: 2.25em auto 2.25em;
    width: 8em;
    .ccalc-plus,
    .ccalc-minus{
      width: 2.25em;
    }
    input{
      height: 2.25em;
    }
  }
  &__delete{
    padding: 0;
    width: 2.25em;
  }
}


@media screen and (max-width: 992px){
  .cart{
    &__nav{
      display: grid;
      grid-template-columns: 100%;
      gap: 1em;
      margin-top: 2em;
    }
    &__delete,
    &__save{
      margin: 0;
    }
  }

  .cart-table{
    margin-top: 2em;
    td{
      grid-template-columns: 50% 50%;
    }
    tr:not(.cart-table__all){
      position: relative;
      td{
        &:last-child{
          .m-table__desc{
            grid-column: 2/3;
            border: none;
            padding: .75em 1em 1.25em 1em;
          }
        }
      }
    }
    &__all{
      display: flex !important;
      flex-wrap: wrap;
      padding: .5em 0;
      td{
        height: unset !important;
        min-height: unset !important;
        width: unset !important;
        border: none !important;
        display: block !important;
        &:nth-child(1){
          width: 100%;
          margin-bottom: 1em;
        }
        &:nth-child(2){
          margin-right: .5em;
        }
      }
    }
    &__all2{
      padding: .5em 0 2em;
      td{
        grid-template-columns: 100%;
        border: none !important;
      }
    }
    &__confirm,
    &__delete{
      width: 100%;
    }
    &__check{
      position: absolute;
      left: 1em;
      bottom: 1.25em;
      width: calc(50% - 2em);
      height: 2.25em;
      label{
        height: 100%;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border: $border;
        border-radius: .5em;
        justify-content: center;
      }
    }
    &__name{
      display: block;
      flex-shrink: unset;
    }
    .cartcalc{
      padding: .75em 0;
    }
    .m-table{
      &__title,
      &__desc{
        padding: .375em 1em;
      }
    }
  }
}
