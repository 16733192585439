@import "../mixins";
.thank-advantages{
  margin-top: 1.5em;
  @include grid3;
}

.thank-advantage{
  display: flex;
  &__icon{
    width: 1.5em;
    margin-right: 1em;
    flex-shrink: 0;
    img{
      width: 100%;
    }
  }
  &__content{
    max-width: 18em;
  }
  &__desc{
    margin-top: .5em;
    a{
      color: $defaultBlue;
    }
  }
}

.thank-order{
  border: $border2;
  box-shadow: $shadow;
  margin-top: 1.5em;
  border-radius: 1em;
  &__top{
    padding: 2.25em 2em 1.5em;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__status{
    display: flex;
    align-items: center;
    .m-badge{
      margin-left: 1em;
      height: 1.5em;
      padding: 0 .625em;
    }
  }
  &__content{
    padding: .625em 2em 2em;
  }
  .m-table{
    th,td{
      &:nth-child(2){
        width: 11em;
      }
    }
  }
}

.thank-pay{
  margin-top: 3em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
  &__bank{
    display: flex;
    align-items: center;
    margin-top: .25em;
    .m-badge{
      margin-left: .625em;
    }
  }
}

.thank-contacts{
  @include grid3;
  margin-top: 3.5em;
}


@media screen and (max-width: 992px){
  .thank-advantages{
    gap: 2em;
    margin-top: .5em;
  }

  .thank-order{
    &__top{
      display: block;
      padding: 2em 1em 1.5em;
      .info-block__title{
        font-size: 2em;
      }
    }
    &__content{
      padding: .625em 1em 1.5em;
    }
  }

  .thank-pay{
    grid-template-columns: 100%;
    margin-top: 1.5em;
  }

  .thank-contacts{
    margin-top: 4em;
  }
}
