@import "vars";
@mixin font-face($name, $file, $weight: 400, $style: normal) {
    @font-face {
        font-family: "#{$name}";
        src: url('../fonts/#{$file}.ttf') format('ttf'),
        url('../fonts/#{$file}.woff2') format('woff2'),
        url('../fonts/#{$file}.woff') format('woff'),
        url('../fonts/#{$file}.eot') format('eot');
        font-weight: $weight;
        font-style: $style;
        font-display: swap;
    }
}

@mixin scroll {
    &::-webkit-scrollbar {
        width: 3px;
    }
    &::-webkit-scrollbar-track {
        background: $yellow;
        border-radius: 3px;
    }
    &::-webkit-scrollbar-thumb {
        background: $black;
        border-radius: 3px;
    }
}

@mixin grid100gap($gap) {
    display: grid;
    grid-template-columns: 100%;
    gap: $gap;
}

@mixin bgImg {
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center;
}

@mixin bgImgSize($size) {
    background-size: $size auto;
    background-repeat: no-repeat;
    background-position: center;
}

@mixin fullScroll {
    width: calc(100% + 2em);
    margin-left: -1em;
    padding: 0 1em;
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
}

@mixin center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin grid2 {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - .75em));
    gap: 1.5em;
    @media screen and (max-width: 992px){
        grid-template-columns: 100%;
    }
}

@mixin grid3 {
    display: grid;
    grid-template-columns: repeat(3,calc(100% / 3 - 1em));
    gap: 1.5em;
    @media screen and (max-width: 992px){
        grid-template-columns: 100%;
    }
}
