@import "../mixins";
.breadcrumbs {
    padding: 1.5em 0;
    margin-bottom: 3em;
    &__item {
        font-weight: 500;
        font-size: .75em;
        line-height: 143%;
        position: relative;
        display: inline-flex;
        color: $grayText;
        &:first-child{
            font-size: 1em;
            width: 1.25em;
            height: 1.25em;
            background-image: url(../img/home.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% auto;
        }
        &:last-child {
            color: $lightBlue;
        }
        &:not(:first-child) {
            margin-left: 2.5em;
            transform: translateY(-.5em);
            &:before {
                content: '';
                position: absolute;
                z-index: 1;
                width: 1.33em;
                height: 1.33em;
                background-image: url(../img/chevron-right.svg);
                @include bgImg;
                top: 0;
                right: 100%;
                margin-right: .75em;
            }
        }
    }
}


@media screen and (max-width: 992px){
    .breadcrumbs{
        margin-bottom: 1em;
        &__item{
            &:first-child{
                &~.breadcrumbs__item{
                    display: none;
                }
            }
            &:last-child{
                display: inline-flex !important;
            }
        }
    }
}
