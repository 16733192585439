@import "../mixins";
.catalog-card{
  &__img{
    padding-bottom: 106%;
    position: relative;
    display: block;
    &:hover{
      img{
        transform: translate(-50%,-50%) scale(1.1);
      }
    }
    img{
      transition: all .3s;
      position: absolute;
      left: 50%;
      top: 50%;
      max-width: 100%;
      max-height: 100%;
      transform: translate(-50%,-50%);
    }
  }
  &__content{
    margin-top: 1.5em;
    @include grid100gap(.25em);
  }
}

.advantage-card{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  &__icon{
    margin-bottom: 1em;
  }
  &__info{
    @include grid100gap(.5em);
  }
}

.spoiler-card{
  &__show{
    cursor: pointer;
  }
  &__hidden{
    display: none;
  }
}

.qa-card{
  &._active{
    .qa-card__btn{
      background-image: url(../img/minus-circle.svg);
    }
  }
  &__show{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__btn{
    flex-shrink: 0;
    margin-left: 3em;
    width: 1.5em;
    height: 1.5em;
    background-image: url(../img/plus-circle.svg);
    background-size: 100% auto;
    background-position: center;
    background-repeat: no-repeat;
    transition: all .4s;
  }
  &__hidden{
    padding: .5em 3em 0 0;
  }
}

.part-card,
.part-card2{
  &._active{
    .part-card__show{
      .link-icon{
        background-image: url(../img/minus.svg);
      }
    }
  }
}

.part-card{
  margin-top: 1.5em;
  padding: 1em 2em;
  width: calc(100% + 4em);
  margin-left: -2em;
  background: #FFFFFF;
  border: 1px solid #E4E7EC;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  border-radius: .5em;
  &__show{
    display: flex;
    align-items: center;
    .link-icon{
      transition: all .4s;
      background-image: url(../img/plus.svg);
      margin-right: .75em;
    }
  }
  &__info{
    padding: .125em 0 1em;
    @include grid100gap(1.5em);
  }
  &__desc{
    padding-left: 2em;
  }
  &__grid{
    column-count: 3;
    column-gap: 2em;
    .model-link{
      &:not(:last-child){
        margin-bottom: .75em;
      }
    }
  }
}


@media screen and (max-width: 992px){
  .catalog-card{
    &__img{
      padding-bottom: 85.3%;
    }
    &__desc{
      span{
        font-size: 1em;
      }
    }
  }

  .advantage-card{
    &__icon{
      margin-bottom: .875em;
    }
    &__info{
      gap: .25em;
    }
  }

  .part-card{
    width: 100%;
    padding: 1em;
    margin-left: 0;
    &__grid{
      column-count: 1;
    }
  }
}
